import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSurveys, createSurvey, deleteSurvey, duplicateSurvey } from './serverComm';
import { BarChart2, Settings, Eye, Link, Copy, Trash2, BarChart3, Link2 } from 'lucide-react';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import copy from 'clipboard-copy';
import frame39 from './frame-39.svg';
import startWithTemplate from './start-with-temp.svg';
import pollPrevBg from './poll-prev-bg.svg';
import LoadingAnimation from './components/LoadingAnimation';

const backgroundColors = [
  '#FFD2D5',  // Pink
  '#FFE8CE',  // Orange
  '#DCE1FF',  // Blue
  '#FFFEC1',  // Yellow
  '#D3FBF1',  // Mint
];

const BackgroundSVG = () => (
  <img 
    src={pollPrevBg}
    alt=""
    className="absolute right-0 top-0 pointer-events-none w-[301px] h-[294px]"
    style={{ mixBlendMode: 'soft-light' }}
  />
);

const SurveyList = () => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user] = useAuthState(auth);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [isDuplicating, setIsDuplicating] = useState(false);

  const fetchSurveys = async () => {
    if (!user) {
      console.log('No user found, skipping fetch');
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const fetchedSurveys = await getSurveys();
      console.log('User:', user.uid);
      console.log('Raw fetched surveys:', JSON.stringify(fetchedSurveys, null, 2));
      
      if (Array.isArray(fetchedSurveys)) {
        console.log('Number of surveys:', fetchedSurveys.length);
        console.log('First survey sample:', fetchedSurveys[0]);
      } else {
        console.log('Fetched surveys is not an array:', typeof fetchedSurveys);
      }
      
      setSurveys(fetchedSurveys || []);
    } catch (error) {
      console.error('Error details:', error);
      setError('Failed to load surveys');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveys();
  }, [user]);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center text-red-600">
        {error}
      </div>
    );
  }

  if (!surveys.length) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div>No surveys found. Create your first survey!</div>
      </div>
    );
  }

  const handleNewSurvey = async () => {
    try {
      setIsLoading(true);
      const response = await createSurvey();
      console.log('Created survey:', response);
      navigate(`/survey/${response.surveyId}`);
    } catch (error) {
      console.error('Error creating new survey:', error);
      setError('Failed to create survey');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewSurvey = (e, surveyId) => {
    e.stopPropagation(); // Prevent the card click event from firing
    window.open(`/s/${surveyId}`, '_blank');
  };

  const handleViewAnalytics = (e, surveyId) => {
    e.stopPropagation(); // Prevent the card click event from firing
    navigate(`/survey/${surveyId}/analytics`);
  };

  const handleDeleteClick = (e, survey) => {
    e.stopPropagation();
    console.log('Setting survey to delete:', survey);
    setSurveyToDelete(survey);
    setDeleteModalOpen(true);
    setOpenMenuId(null);
  };

  const handleConfirmDelete = async () => {
    if (surveyToDelete) {
      try {
        setDeletingId(surveyToDelete.surveyId);
        console.log('Deleting survey:', surveyToDelete.surveyId);
        
        await deleteSurvey(surveyToDelete.surveyId);
        
        // Update local state to remove the deleted survey
        setSurveys(prevSurveys => 
          prevSurveys.filter(s => s.surveyId !== surveyToDelete.surveyId)
        );
        
        setDeleteModalOpen(false);
        setSurveyToDelete(null);
      } catch (error) {
        console.error('Error deleting survey:', error);
        // Show error message to user
        alert('Failed to delete survey. Please try again.');
      } finally {
        setDeletingId(null);
      }
    }
  };

  const handleCopyLink = (e, surveyId) => {
    e.stopPropagation();
    const link = `${window.location.origin}/s/${surveyId}`;
    copy(link).then(() => {
      setCopiedId(surveyId);
      setTimeout(() => setCopiedId(null), 3000);
    }).catch(err => console.error('Failed to copy:', err));
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSettingsClick = (e, surveyId) => {
    e.stopPropagation();
    setOpenMenuId(openMenuId === surveyId ? null : surveyId);
  };

  const handleMenuMouseLeave = () => {
    setOpenMenuId(null);
  };

  const handleDuplicateSurvey = async (e, surveyId) => {
    e.stopPropagation();
    try {
      setIsDuplicating(true);
      const duplicatedSurvey = await duplicateSurvey(surveyId);
      setSurveys(prevSurveys => [duplicatedSurvey, ...prevSurveys]);
      setOpenMenuId(null);
    } catch (error) {
      console.error('Error duplicating survey:', error);
    } finally {
      setIsDuplicating(false);
    }
  };

  const getBackgroundColor = (index) => {
    return backgroundColors[index % backgroundColors.length];
  };

  const handleUpgradeClick = () => {
    console.log('Upgrade button clicked');
    navigate('/pricing');
  };

  const handleCardClick = (surveyId) => {
    navigate(`/survey/${surveyId}`);
  };

  return (
    <>
      {(isLoading || isDuplicating) && <LoadingAnimation />}
      
      <div className="w-full sm:w-[70%] mx-auto min-h-screen bg-white py-24 sm:py-24 px-4 sm:px-0">
        <div className="flex justify-between items-center mb-6 sm:mb-8">
          <h1 className="text-[18px] sm:text-[21px] leading-[24px] sm:leading-[31px] font-['HK_Nova'] font-medium">
            Welcome to PollBolt, {user?.displayName || 'Guest'}. 🙌⚡⚡
          </h1>
        </div>

        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mb-6 sm:mb-8">
          <div 
            onClick={handleNewSurvey}
            className="bg-[#FFFD63] p-4 sm:p-8 rounded-[20px] shadow-md cursor-pointer hover:bg-[#f7f55f] transition-colors flex items-center justify-center gap-4 w-full sm:max-w-[294px]"
          >
            <img 
              src={frame39} 
              alt="New Survey" 
              className="w-8 sm:w-11 h-8 sm:h-11 flex-shrink-0"
            />
            <span className="text-black font-bold text-[18px] sm:text-[21px] leading-4 tracking-[-0.21px] whitespace-nowrap">
              Start from scratch
            </span>
          </div>

          <div 
            onClick={() => {/* Add your template handler here */}}
            className="bg-white border border-[#EBEBEB] p-4 sm:p-8 rounded-[20px] cursor-pointer hover:bg-gray-50 transition-colors flex items-center justify-center gap-4 w-full sm:max-w-[294px]"
          >
            <img 
              src={startWithTemplate} 
              alt="Template" 
              className="w-8 sm:w-11 h-8 sm:h-11 flex-shrink-0"
            />
            <span className="text-black font-bold text-[18px] sm:text-[21px] leading-4 tracking-[-0.21px] whitespace-nowrap">
              Start with Template
            </span>
          </div>
        </div>

        <div className="mb-4 sm:mb-6">
          <h2 
            className="text-black text-[18px] sm:text-[21px] text-left font-['HK_Nova'] font-medium leading-[21px]"
          >
            My Poll's
          </h2>
        </div>

        <div className="w-full h-px bg-gray-200 mb-4 sm:mb-6"></div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {surveys.map((survey, index) => (
            <div 
              key={survey.surveyId}
              onClick={() => handleCardClick(survey.surveyId)}
              className="relative bg-white border border-[#e4e4e4] rounded-[15px] transition-all duration-200 hover:shadow-lg hover:-translate-y-1 cursor-pointer h-[380px] sm:h-[439px]"
            >
              <div 
                className="absolute inset-x-2.5 sm:inset-x-3.5 top-2.5 sm:top-3.5 h-[250px] sm:h-[294px] rounded-[10px] overflow-hidden border border-[#e4e4e4]"
                style={{ backgroundColor: getBackgroundColor(index) }}
              >
                <div className="relative w-full h-full">
                  <img 
                    src={pollPrevBg}
                    alt=""
                    className="absolute right-0 top-0 pointer-events-none w-[301px] h-[294px]"
                    style={{ mixBlendMode: 'soft-light' }}
                  />

                  <div className="absolute top-3 sm:top-4 right-3 sm:right-4 flex gap-2 sm:gap-3 z-20">
                    <button
                      onClick={(e) => handleViewAnalytics(e, survey.surveyId)}
                      className="w-[42px] h-[42px] bg-black rounded-[10px] shadow-lg flex items-center justify-center hover:bg-gray-800 transition-colors"
                    >
                      <BarChart3 className="w-6 h-6 text-white" />
                    </button>

                    <div className="relative">
                      <button
                        onClick={(e) => handleSettingsClick(e, survey.surveyId)}
                        className="w-[42px] h-[42px] bg-black rounded-[10px] shadow-lg flex items-center justify-center hover:bg-gray-800 transition-colors"
                      >
                        <Settings className="w-6 h-6 text-white" />
                      </button>

                      {openMenuId === survey.surveyId && (
                        <div 
                          onClick={(e) => e.stopPropagation()}
                          className="absolute top-full right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-30 border border-gray-200"
                          onMouseLeave={handleMenuMouseLeave}
                        >
                          <div className="py-1">
                            <button
                              onClick={(e) => handleDuplicateSurvey(e, survey.surveyId)}
                              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              <Copy className="w-4 h-4 mr-2" />
                              Duplicate
                            </button>
                            <button
                              onClick={(e) => handleDeleteClick(e, survey)}
                              className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                            >
                              <Trash2 className="w-4 h-4 mr-2" />
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="absolute left-[20px] sm:left-[29px] bottom-[20px] sm:bottom-[29px]">
                    <h2 className="text-[20px] sm:text-[24px] font-['HK_Nova'] font-medium leading-[24px] sm:leading-[28px] tracking-[-0.93px] text-black mb-4 max-w-[280px] sm:max-w-[301px]">
                      {survey.title}
                    </h2>
                    <div className="flex items-center gap-2">
                      <span className="text-base font-medium text-black tracking-[-0.48px]">
                        Status
                      </span>
                      <span className="bg-white text-black px-3 py-1 text-base font-medium tracking-[-0.48px] rounded-full">
                        OPEN
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="absolute inset-x-[30px] sm:inset-x-[43px] bottom-[30px] sm:bottom-[43px] flex items-center justify-between">
                <div>
                  <div className="text-base font-medium text-[#7b7b7d] tracking-[-0.48px] mb-2">
                    Total responses
                  </div>
                  <div className="text-4xl font-bold text-black tracking-[-1.08px]">
                    {survey.responseCount || 0}
                  </div>
                </div>

                <div className="flex items-center gap-3 sm:gap-4">
                  <div className="flex items-center gap-2 text-[#7b7b7d]">
                    <Eye className="w-5 h-5" />
                    <span className="text-base font-medium tracking-[-0.48px]">
                      {survey.viewCount || 0}
                    </span>
                  </div>
                  <button 
                    onClick={(e) => handleCopyLink(e, survey.surveyId)}
                    className="flex items-center text-base font-medium text-[#7b7b7d] tracking-[-0.48px] hover:text-gray-900 transition-colors"
                  >
                    <Link2 className="w-4 h-4 mr-2" />
                    {copiedId === survey.surveyId ? "Copied!" : "Share"}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {deleteModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
              <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
              <p className="mb-4">Are you sure you want to delete this survey? This action cannot be undone.</p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setDeleteModalOpen(false)}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmDelete}
                  className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SurveyList;
