import React, { useState } from 'react';
import { GripVertical, Trash2, Edit2, Check, X, Star, Settings } from 'lucide-react';
import LogicConfigurator from './LogicConfigurator';
import { motion, AnimatePresence } from 'framer-motion';

const QuestionRenderer = ({ 
  question = {},
  answer, 
  onAnswerChange, 
  isEditable, 
  onDelete,
  dragHandleProps,
  onQuestionUpdate,
  questions
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState({
    ...question,
    options: question.options || []
  });
  const [rating, setRating] = useState(0);
  const [showLogicConfig, setShowLogicConfig] = useState(false);

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleSaveEdit = (e) => {
    e.stopPropagation();
    onQuestionUpdate(editedQuestion);
    setIsEditing(false);
  };

  const handleCancelEdit = (e) => {
    e.stopPropagation();
    setEditedQuestion({
      ...question,
      options: question.options || []
    });
    setIsEditing(false);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...(editedQuestion.options || [])];
    updatedOptions[index] = value;
    setEditedQuestion({ ...editedQuestion, options: updatedOptions });
  };

  const addOption = () => {
    setEditedQuestion({
      ...editedQuestion,
      options: [...(editedQuestion.options || []), '']
    });
  };

  const removeOption = (indexToRemove) => {
    setEditedQuestion({
      ...editedQuestion,
      options: (editedQuestion.options || []).filter((_, index) => index !== indexToRemove)
    });
  };

  const options = question.options || [];

  const renderAnswerInput = () => {
    switch (question.type) {
      case 'short text':
      case 'long text':
      case 'numeric':
        return (
          <input
            type={question.type}
            placeholder="Enter your answer"
            className="w-full p-2 border border-gray-300 rounded-lg"
            disabled={isEditable}
          />
        );

      case 'star rating':
        return (
          <div className="flex items-center gap-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                size={32}
                onClick={() => !isEditable && setRating(star)}
                className={`cursor-pointer transition-colors ${
                  rating >= star 
                    ? 'text-blue-500' 
                    : 'text-gray-300 hover:text-blue-500'
                }`}
                fill="currentColor"
              />
            ))}
          </div>
        );

      default:
        return (
          <div className="space-y-2">
            {options.map((option, index) => (
              <motion.label
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                className={`
                  relative flex items-center p-4 rounded-lg cursor-pointer
                  border transition-all duration-200 overflow-hidden
                  ${!isEditable && answer === option 
                    ? 'border-blue-500 bg-blue-50 text-blue-700' 
                    : 'border-gray-200 hover:border-gray-300 hover:bg-gray-50'
                  }
                `}
                onClick={() => !isEditable && onAnswerChange(option)}
              >
                <AnimatePresence>
                  {answer === option && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ 
                        opacity: [0, 0.5, 0, 0.5, 0],
                        scale: [1, 1.05, 1, 1.05, 1]
                      }}
                      transition={{ 
                        duration: 0.8,
                        times: [0, 0.2, 0.4, 0.6, 1],
                        ease: "easeInOut"
                      }}
                      className="absolute inset-0 bg-blue-500"
                    />
                  )}
                </AnimatePresence>

                <input
                  type={question.type === 'multiple' ? 'checkbox' : 'radio'}
                  name={`question-${question.id}`}
                  checked={answer === option}
                  onChange={() => !isEditable && onAnswerChange(option)}
                  className="relative z-10 w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="relative z-10 ml-3">
                  {option}
                </span>
              </motion.label>
            ))}
          </div>
        );
    }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={question.id}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.3 }}
        className="space-y-4"
      >
        <h3 className="text-lg font-medium text-gray-900">
          {question.text}
        </h3>
        {renderAnswerInput()}
      </motion.div>
    </AnimatePresence>
  );
};

export default QuestionRenderer;
