import React, { useEffect, createContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import SurveyDesigner from './ui/src/SurveyDesigner';
import SurveyList from './ui/src/SurveyList';
import NavBar from './ui/src/NavBar';
import SignIn from './ui/src/SignIn';
import LandingPage from './ui/src/LandingPage';
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import SurveyViewer from './ui/src/SurveyViewer';
import SurveyAnalytics from './ui/src/SurveyAnalytics';
import PricingPage from './ui/src/PricingPage';
import SurveyOverview from './ui/src/SurveyOverview';
import SurveyResponses from './ui/src/SurveyResponses';
import SurveySettings from './ui/src/SurveySettings';
import LoadingAnimation from './ui/src/components/LoadingAnimation';

export const UserContext = createContext(null);

const firebaseConfig = {
  apiKey: "AIzaSyCIUDcL-SK_5bAm0t4KkfnWcln18n8yrfg",
  authDomain: "surveyappreact.firebaseapp.com",
  projectId: "surveyappreact",
  storageBucket: "surveyappreact.appspot.com",
  messagingSenderId: "366662516624",
  appId: "1:366662516624:web:15257e6d56c2c56ee83d15"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function App() {
  const [user, loading, error] = useAuthState(auth);
  const [showSignIn, setShowSignIn] = useState(false);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    setInitializing(false);
  }, []);

  const openSignIn = () => setShowSignIn(true);
  const closeSignIn = () => setShowSignIn(false);

  // Protected route handler
  const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const isSurveyPath = location.pathname.startsWith('/s/');
    
    if (!user && !isSurveyPath) {
      return <Navigate to="/" replace />;
    }
    
    return children;
  };

  if (loading || initializing) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="w-full min-h-screen pt-14">
      <UserContext.Provider value={user}>
        <Router>
          <div className="min-h-screen bg-white">
            {user && <NavBar openSignIn={openSignIn} />}
            <main className="w-full">
              <Routes>
                {/* Public routes */}
                <Route 
                  path="/" 
                  element={user ? <Navigate to="/surveys" /> : <LandingPage openSignIn={openSignIn} />} 
                />
                <Route path="/s/:surveyId" element={<SurveyViewer />} />

                <Route 
                  path="/surveys" 
                  element={
                    <ProtectedRoute>
                      <SurveyList />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/survey/:surveyId" 
                  element={
                    <ProtectedRoute>
                      <SurveyDesigner />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/survey/:surveyId/analytics" 
                  element={
                    <ProtectedRoute>
                      <SurveyAnalytics />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/pricing" 
                  element={
                    <ProtectedRoute>
                      <PricingPage />
                    </ProtectedRoute>
                  } 
                />
                <Route path="/survey/:id/overview" element={<SurveyOverview />} />
                <Route path="/survey/:id/analytics" element={<SurveyAnalytics />} />
                <Route path="/survey/:id/responses" element={<SurveyResponses />} />
                <Route path="/survey/:id/settings" element={<SurveySettings />} />
              </Routes>
            </main>
            {showSignIn && <SignIn onClose={closeSignIn} />}
          </div>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
