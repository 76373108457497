import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoPollbolt from './logo-pollbolt-main.svg';
import HeroDash from './lp-hero-dash.svg';

const LandingPage = ({ openSignIn }) => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen w-full bg-[#FFFD63] absolute inset-0">
      <div className="max-w-[1560px] mx-auto px-4">
        {/* Header with logo and login */}
        <div className="flex justify-between items-center p-8">
          <img src={LogoPollbolt} alt="PollBolt Logo" className="h-8" />
          <button 
            className="px-6 py-2 text-base rounded-md bg-black text-white hover:bg-gray-800 transition-all duration-300 font-['HK_Nova']"
            onClick={openSignIn}
          >
            Login
          </button>
        </div>

        {/* Main content */}
        <div className="flex items-center justify-center p-8">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="font-['HK_Nova'] font-bold text-[62px] leading-[68.44px] tracking-[-2.489px] mb-4">
              Lightning Fast Poll's,<br />
              Insightful Feedback.
            </h1>
            <p className="font-['HK_Nova'] text-[18px] leading-[25px] font-normal text-black">
              Discover the powerful survey platform anyone can use.<br />
              Made for those who need answers fast.
            </p>
            <div className="flex gap-4 justify-center mt-8">
              <button 
                className="px-8 py-3 text-lg rounded-md bg-black text-white hover:bg-gray-800 transition-all duration-300 font-['HK_Nova']"
                onClick={openSignIn}
              >
                Get Started
              </button>
            </div>
            {/* Hero image */}
            <div className="mt-12">
              <img src={HeroDash} alt="Dashboard Preview" className="max-w-full h-auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
