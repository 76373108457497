import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { getSurveyWithResponses } from './serverComm';
import { saveAs } from 'file-saver';
import LoadingAnimation from './components/LoadingAnimation';
import { Share2, Copy, Check, X } from 'lucide-react';

const THEME_COLORS = {
  pink: '#FFD2D5',
  orange: '#FFE8CE',
  purple: '#DCE1FF',
  gray: '#E4E4E4',
};

const CHART_COLORS = [THEME_COLORS.pink, THEME_COLORS.orange, THEME_COLORS.purple, THEME_COLORS.gray];

const GRADIENTS = {
  pink: 'linear-gradient(135deg, #FFD2D5 0%, #FFC5C9 100%)',
  orange: 'linear-gradient(135deg, #FFE8CE 0%, #FFD7AD 100%)',
  purple: 'linear-gradient(135deg, #DCE1FF 0%, #C5CDFF 100%)',
};

const SurveyAnalytics = () => {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [chartType, setChartType] = useState('bar');
  const navigate = useNavigate();
  const [showShareModal, setShowShareModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState('idle'); // 'idle' | 'copied'

  useEffect(() => {
    fetchSurveyData();
  }, [surveyId]);

  const fetchSurveyData = async () => {
    try {
      setLoading(true);
      const data = await getSurveyWithResponses(surveyId);
      setSurvey(data);
      setSelectedQuestion(data.questions[0]);
    } catch (err) {
      setError('Failed to fetch survey data');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingAnimation />;
  if (error) return <div>Error: {error}</div>;
  if (!survey) return <div>No survey data available</div>;

  const getQuestionData = () => {
    if (!selectedQuestion) return [];

    const responseCounts = {};
    survey.responses.forEach(response => {
      const answer = response.answers[selectedQuestion.id];
      responseCounts[answer] = (responseCounts[answer] || 0) + 1;
    });

    return Object.entries(responseCounts).map(([label, value]) => ({ label, value }));
  };

  const renderChart = () => {
    const data = getQuestionData();

    const commonProps = {
      contentStyle: { 
        backgroundColor: 'white',
        border: `1px solid ${THEME_COLORS.purple}`,
        borderRadius: '8px',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)'
      }
    };

    if (chartType === 'bar') {
      return (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke={THEME_COLORS.gray} />
            <XAxis dataKey="label" stroke="#6B7280" />
            <YAxis stroke="#6B7280" />
            <Tooltip {...commonProps} />
            <Bar dataKey="value" radius={[4, 4, 0, 0]}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (chartType === 'pie') {
      return (
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={160}
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />
              ))}
            </Pie>
            <Tooltip {...commonProps} />
          </PieChart>
        </ResponsiveContainer>
      );
    }
  };

  const handleExport = () => {
    const surveyData = {
      title: survey.title,
      responses: survey.responses,
      questions: survey.questions,
      analytics: getQuestionData()
    };
    
    const blob = new Blob([JSON.stringify(surveyData, null, 2)], {
      type: "application/json"
    });
    saveAs(blob, `${survey.title}-export.json`);
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handleSaveReport = () => {
    console.log('Saving report...');
  };

  const handleCopyLink = async () => {
    const surveyUrl = `${window.location.origin}/s/${surveyId}`;
    try {
      await navigator.clipboard.writeText(surveyUrl);
      setCopyStatus('copied');
      setTimeout(() => setCopyStatus('idle'), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="min-h-screen bg-white relative pt-12">
      {/* Header Section */}
      <div className="border-b border-gray-100 bg-white">
        <div className="px-8 py-8">
          {/* Main Title and Info */}
          <div className="flex flex-col gap-6 sm:flex-row sm:items-center sm:justify-between">
            {/* Left Side - Title and Details */}
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <svg 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  className="text-gray-600"
                >
                  <path d="M3 3v18h18"></path>
                  <path d="M18 17V9"></path>
                  <path d="M13 17V5"></path>
                  <path d="M8 17v-3"></path>
                </svg>
                <h1 className="text-3xl font-bold text-gray-900">{survey.title}</h1>
              </div>
              <div className="flex flex-wrap items-center gap-3 text-sm text-gray-500">
                <span>Last updated: {new Date().toLocaleDateString()}</span>
                <span className="h-1 w-1 rounded-full bg-gray-300"></span>
                <span>ID: {survey.id}</span>
                <div className="flex items-center gap-2 px-3 py-1 rounded-lg bg-opacity-50"
                     style={{ backgroundColor: THEME_COLORS.purple }}>
                  <span className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></span>
                  <span className="text-gray-700">Live Updates</span>
                </div>
              </div>
            </div>

            {/* Right Side - Actions */}
            <div className="flex items-center gap-3">
              <button 
                onClick={handleExport}
                className="flex items-center gap-2 px-4 py-2 rounded-lg text-gray-600 hover:bg-gray-50 border border-gray-200 transition-all"
              >
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="7 10 12 15 17 10"></polyline>
                  <line x1="12" y1="15" x2="12" y2="3"></line>
                </svg>
                Export
              </button>
              <button 
                onClick={handleShare}
                className="flex items-center gap-2 px-4 py-2 rounded-lg text-gray-600 hover:bg-gray-50 border border-gray-200 transition-all"
              >
                <Share2 size={16} />
                Share
              </button>
              <button 
                onClick={handleSaveReport}
                className="flex items-center gap-2 px-4 py-2 rounded-lg text-gray-700 transition-all hover:shadow-md"
                style={{ background: THEME_COLORS.orange }}
              >
                Save Report
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="px-8 py-8">
        {/* Enhanced Overview Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {[
            {
              title: 'Total Responses',
              value: survey.responses.length,
              change: '+12%',
              gradient: GRADIENTS.pink
            },
            {
              title: 'Average Time',
              value: '4.2 min',
              change: '-0.5 min',
              gradient: GRADIENTS.orange
            },
            {
              title: 'Completion Rate',
              value: '89%',
              change: '+5%',
              gradient: GRADIENTS.purple
            }
          ].map((card, index) => (
            <div 
              key={index}
              className="rounded-xl p-6 relative overflow-hidden transition-transform hover:scale-[1.02]"
              style={{ background: card.gradient }}
            >
              <div className="relative z-10">
                <h3 className="text-sm font-medium text-gray-600">{card.title}</h3>
                <p className="mt-2 text-3xl font-bold text-gray-900">{card.value}</p>
                <div className="mt-2 flex items-center gap-2">
                  <span className={`text-sm ${card.change.includes('+') ? 'text-green-600' : 'text-red-600'}`}>
                    {card.change}
                  </span>
                  <span className="text-sm text-gray-600">from last week</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Controls and Stats */}
          <div className="lg:col-span-1 space-y-6">
            {/* Active Question Select Card - Moved and Restyled */}
            <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
              <h3 className="text-lg font-semibold mb-4">Active Question</h3>
              <select
                className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50 transition-all text-sm"
                style={{ focusRing: THEME_COLORS.purple }}
                value={selectedQuestion?.id}
                onChange={(e) => setSelectedQuestion(survey.questions.find(q => q.id === e.target.value))}
              >
                {survey.questions.map(question => (
                  <option key={question.id} value={question.id}>
                    {question.text}
                  </option>
                ))}
              </select>
              <div className="flex items-center justify-between mt-3">
                <span className="text-sm text-gray-500">
                  Question {survey.questions.indexOf(selectedQuestion) + 1} of {survey.questions.length}
                </span>
                <button className="text-sm text-gray-600 hover:text-gray-900">
                  View All
                </button>
              </div>
            </div>

            {/* Chart Type Selection */}
            <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
              <h3 className="text-lg font-semibold mb-4">Visualization</h3>
              <div className="grid grid-cols-2 gap-3">
                {['bar', 'pie'].map((type) => (
                  <button
                    key={type}
                    className={`p-4 rounded-lg border transition-all text-center ${
                      chartType === type
                        ? 'border-transparent shadow-sm'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                    style={{ 
                      backgroundColor: chartType === type ? THEME_COLORS.orange : 'white'
                    }}
                    onClick={() => setChartType(type)}
                  >
                    <div className="flex flex-col items-center gap-2">
                      {type === 'bar' ? (
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                          <rect x="3" y="12" width="6" height="8" rx="1"/>
                          <rect x="9" y="8" width="6" height="12" rx="1"/>
                          <rect x="15" y="4" width="6" height="16" rx="1"/>
                        </svg>
                      ) : (
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                          <path d="M21.21 15.89A10 10 0 1 1 8 2.83"/>
                          <path d="M22 12A10 10 0 0 0 12 2v10z"/>
                        </svg>
                      )}
                      <span className="block text-sm font-medium">
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {/* Response Details */}
            <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
              <h3 className="text-lg font-semibold mb-4">Response Details</h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center p-3 rounded-lg bg-gray-50">
                  <span className="text-sm text-gray-600">Most Common</span>
                  <span className="font-medium">{getQuestionData().sort((a, b) => b.value - a.value)[0]?.label || 'N/A'}</span>
                </div>
                <div className="flex justify-between items-center p-3 rounded-lg bg-gray-50">
                  <span className="text-sm text-gray-600">Question Type</span>
                  <span className="font-medium">{selectedQuestion?.type || 'N/A'}</span>
                </div>
                <div className="flex justify-between items-center p-3 rounded-lg bg-gray-50">
                  <span className="text-sm text-gray-600">Skip Rate</span>
                  <span className="font-medium">2.3%</span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Chart */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-xl shadow-sm p-8 border border-gray-100 min-h-[600px]">
              <div className="flex items-start justify-between mb-6">
                <div>
                  <h3 className="text-lg font-semibold">Response Distribution</h3>
                  <p className="text-sm text-gray-500 mt-1">
                    Showing data for {selectedQuestion?.text}
                  </p>
                </div>
                <div className="flex items-center gap-4">
                  <button className="text-sm text-gray-600 hover:text-gray-900">
                    Filter
                  </button>
                  <button className="text-sm text-gray-600 hover:text-gray-900">
                    Download
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center">
                {renderChart()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Share Modal */}
      {showShareModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 m-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Share Survey</h3>
              <button
                onClick={() => setShowShareModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={20} />
              </button>
            </div>

            <div className="space-y-4">
              {/* Survey Link */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Survey Link
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    readOnly
                    value={`${window.location.origin}/s/${surveyId}`}
                    className="flex-1 p-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                  <button
                    onClick={handleCopyLink}
                    className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    {copyStatus === 'copied' ? (
                      <>
                        <Check size={16} className="text-green-500" />
                        Copied!
                      </>
                    ) : (
                      <>
                        <Copy size={16} />
                        Copy
                      </>
                    )}
                  </button>
                </div>
              </div>

              {/* Share Options */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Share via
                </label>
                <div className="grid grid-cols-2 gap-3">
                  {[
                    { name: 'Email', icon: 'mail', color: 'bg-blue-50 text-blue-600' },
                    { name: 'WhatsApp', icon: 'message-circle', color: 'bg-green-50 text-green-600' },
                  ].map((option) => (
                    <button
                      key={option.name}
                      className={`flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors ${option.color}`}
                    >
                      <span className="text-sm font-medium">{option.name}</span>
                    </button>
                  ))}
                </div>
              </div>

              {/* Privacy Settings */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Privacy Settings
                </label>
                <select
                  className="w-full p-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                >
                  <option value="public">Public - Anyone with the link</option>
                  <option value="private">Private - Only specific people</option>
                </select>
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowShareModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyAnalytics;

