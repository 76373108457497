import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSurvey, checkResponse } from './serverComm';
import QuestionRenderer from './QuestionRenderer';
import LoadingAnimation from './components/LoadingAnimation';
import watermark from './watermark.svg';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import '@fontsource/inter';
import '@fontsource/montserrat';

function SurveyViewer() {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [hasResponded, setHasResponded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    async function loadSurveyAndCheckResponse() {
      if (!surveyId) {
        setError('No survey ID provided');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const [surveyData, responseCheck] = await Promise.all([
          fetchSurvey(surveyId),
          checkResponse(surveyId)
        ]);
        setSurvey(surveyData);
        setHasResponded(responseCheck.hasResponded);
      } catch (error) {
        console.error('Error fetching survey or checking response:', error);
        setError('Failed to load survey. Please try again.');
      } finally {
        setLoading(false);
      }
    }

    loadSurveyAndCheckResponse();
  }, [surveyId]);

  const handleSubmit = () => {
    setHasResponded(true);
  };

  const handleNext = () => {
    if (currentQuestionIndex < survey.questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const handleAnswerChange = (questionId, answer) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: answer
    }));
  };

  const WatermarkComponent = () => (
    <a 
      href="https://www.pollbolt.com" 
      target="_blank" 
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 z-50 hover:opacity-90 transition-opacity"
    >
      <img 
        src={watermark} 
        alt="Created with PollBolt" 
        className="w-[191px] h-[49px]"
      />
    </a>
  );

  if (loading) {
    return (
      <>
        <LoadingAnimation />
        <WatermarkComponent />
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="text-center py-10 text-red-500">{error}</div>
        <WatermarkComponent />
      </>
    );
  }

  if (!survey) {
    return (
      <>
        <div className="text-center py-10">Survey not found.</div>
        <WatermarkComponent />
      </>
    );
  }

  const surveyStyles = {
    fontFamily: survey.styles?.fontFamily || 'inherit',
    backgroundColor: survey.styles?.backgroundColor || '#FFFFFF',
  };

  if (hasResponded) {
    return (
      <div 
        className="min-h-screen flex items-center justify-center"
        style={{ backgroundColor: survey.styles?.backgroundColor || '#FFFFFF' }}
      >
        <div 
          className="text-center py-10 text-2xl"
          style={{ 
            fontFamily: survey.styles?.fontFamily || 'inherit',
            color: survey.styles?.submitButtonColor || 'rgb(34 197 94)'
          }}
        >
          Thank you for your response!
        </div>
        <WatermarkComponent />
      </div>
    );
  }

  return (
    <div 
      className="min-h-screen"
      style={{ 
        backgroundColor: survey.styles?.backgroundColor || '#FFFFFF',
        fontFamily: survey.styles?.fontFamily || 'Inter, sans-serif'
      }}
    >
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        {/* Progress indicator */}
        <div className="mb-8 text-center">
          <div className="text-sm text-gray-500 mb-2">
            Question {currentQuestionIndex + 1} of {survey.questions.length}
          </div>
          <div className="w-full bg-gray-200 h-2 rounded-full">
            <div 
              className="bg-blue-500 h-2 rounded-full transition-all duration-300"
              style={{ 
                width: `${((currentQuestionIndex + 1) / survey.questions.length) * 100}%`
              }}
            />
          </div>
        </div>

        {/* Current question */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <QuestionRenderer
            question={survey.questions[currentQuestionIndex]}
            answer={answers[survey.questions[currentQuestionIndex].id]}
            onAnswerChange={(answer) => 
              handleAnswerChange(survey.questions[currentQuestionIndex].id, answer)
            }
          />
        </div>

        {/* Navigation buttons */}
        <div className="flex justify-between items-center">
          <button
            onClick={handlePrevious}
            disabled={currentQuestionIndex === 0}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
              ${currentQuestionIndex === 0 
                ? 'text-gray-400 cursor-not-allowed' 
                : 'text-gray-700 hover:bg-gray-100'}`}
          >
            <ChevronLeft size={20} />
            Previous
          </button>

          <button
            onClick={currentQuestionIndex === survey.questions.length - 1 ? handleSubmit : handleNext}
            className="flex items-center gap-2 px-6 py-2 rounded-lg text-white transition-colors"
            style={{ backgroundColor: survey.styles?.submitButtonColor || '#4F46E5' }}
          >
            {currentQuestionIndex === survey.questions.length - 1 ? (
              survey.styles?.useCustomSubmitText && survey.styles?.customSubmitText
                ? survey.styles.customSubmitText
                : 'Submit'
            ) : (
              <>
                Next
                <ChevronRight size={20} />
              </>
            )}
          </button>
        </div>
      </div>
      <WatermarkComponent />
    </div>
  );
}

export default SurveyViewer;
