import React, { useState } from 'react';
import { Check } from "lucide-react";
import { useNavigate } from 'react-router-dom';

const PricingPage = () => {
  const [isAnnual, setIsAnnual] = useState(true);

  const monthlyPrice = 4.99;
  const annualPrice = 3.99;
  const currentPrice = isAnnual ? annualPrice : monthlyPrice;

  return (
    <div className="min-h-screen w-full bg-white">
      <div className="max-w-[1560px] mx-auto px-4 py-16">
        {/* Header */}
        <h1 className="text-center text-[48px] font-medium mb-16 font-['HK_Nova']">
          Your Time to get some Answers
        </h1>

        {/* Pricing Toggle */}
        <div className="flex justify-center mb-16">
          <div className="inline-flex items-center rounded-[20px] bg-white border border-[#E4E4E4] p-2">
            <div className="flex items-center space-x-4">
              <div 
                className={`px-4 py-3 rounded-[16px] transition-colors duration-300 cursor-pointer font-medium
                ${isAnnual ? 'text-black' : 'text-gray-400'}`}
                onClick={() => setIsAnnual(true)}
              >
                <span className={`text-xl relative z-10 ${isAnnual ? 'bg-[#FFFD63] -mx-4 -my-3 px-4 py-3 rounded-[16px] block' : ''}`}>
                  Annual (3 month free)
                </span>
              </div>
              
              <div 
                className={`px-4 py-3 rounded-[16px] transition-colors duration-300 cursor-pointer font-medium
                ${!isAnnual ? 'text-black' : 'text-gray-400'}`}
                onClick={() => setIsAnnual(false)}
              >
                <span className={`text-xl relative z-10 ${!isAnnual ? 'bg-[#FFFD63] -mx-4 -my-3 px-4 py-3 rounded-[16px] block' : ''}`}>
                  Monthly
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing Cards Container */}
        <div className="flex justify-center gap-8">
          {/* Free Plan */}
          <div className="w-[480px] overflow-hidden rounded-3xl border border-gray-200 bg-white">
            <div className="bg-[#FFFEF2] p-8 text-center">
              <h2 className="text-3xl font-medium mb-2">Free</h2>
              <p className="text-xl mb-4">Up to 3 Poll's</p>
              <div className="inline-flex bg-white h-[27px] px-3 items-center rounded-full border border-black">
                <span className="font-bold whitespace-nowrap">Enjoy free</span>
              </div>
            </div>

            <div className="p-8">
              <div className="text-center mb-6">
                <div className="flex items-start justify-center">
                  <span className="text-6xl font-bold">0</span>
                  <span className="text-gray-500 ml-1 mt-3">/mon</span>
                </div>
              </div>

              <p className="text-center text-lg mb-8">
                For individuals and small teams<br />just getting started
              </p>

              <button className="w-full h-14 text-lg rounded-[12px] border border-black">
                Your current plan
              </button>

              <div className="space-y-4 mt-8">
                {[
                  "Up to 3 Poll's per month",
                  "100 responses per Poll's",
                  "Basic analytics",
                  "Email support"
                ].map((feature, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <Check className="w-5 h-5 text-gray-400" />
                    <span className="text-gray-600">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Pro Plan */}
          <div className="w-[480px] overflow-hidden rounded-3xl border border-gray-200 bg-white">
            <div className="bg-[#FFFD63] p-8 text-center">
              <h2 className="text-3xl font-medium mb-2">Pro</h2>
              <p className="text-xl mb-4">Unlimited Poll's</p>
              <div className="inline-flex bg-white h-[27px] px-3 items-center rounded-full border border-black">
                <span className="font-bold whitespace-nowrap">20% OFF</span>
              </div>
            </div>

            <div className="p-8">
              <div className="text-center mb-6">
                <div className="flex items-start justify-center">
                  <span className="text-6xl font-bold">{Math.floor(currentPrice)}</span>
                  <span className="text-3xl font-bold mt-1">
                    {(currentPrice % 1).toFixed(2).substring(2)}
                  </span>
                  <span className="text-gray-500 ml-1 mt-3">/mon</span>
                </div>
              </div>

              <p className="text-center text-lg mb-8">
                For professionals who need<br />more power and customization
              </p>

              <button className="w-full h-14 text-lg rounded-[12px] bg-black text-white hover:bg-black/90">
                Start Now
              </button>

              <div className="space-y-4 mt-8">
                {[
                  "Unlimited Poll's",
                  "1000 responses per poll",
                  "Advanced analytics",
                  "Custom branding",
                  "All question types",
                  "Advanced export options",
                  "Priority email support"
                ].map((feature, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <Check className="w-5 h-5 text-gray-400" />
                    <span className="text-gray-600">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
