import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCIUDcL-SK_5bAm0t4KkfnWcln18n8yrfg",
  authDomain: "surveyappreact.firebaseapp.com",
  projectId: "surveyappreact",
  storageBucket: "surveyappreact.appspot.com",
  messagingSenderId: "366662516624",
  appId: "1:366662516624:web:15257e6d56c2c56ee83d15"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

