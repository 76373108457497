import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import { Edit2, ChevronRight } from 'lucide-react';
import { getSurvey, saveSurvey } from './serverComm';
import debounce from 'lodash/debounce';

const Logo = () => (
  <div className="bg-black h-14 w-[60px] flex items-center justify-center">
    <svg width="55" height="55" viewBox="0 0 71 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="70.47" height="72" fill="black"/>
      <path d="M38.3937 36.3184H46.6395C46.6645 36.3761 46.6877 36.4356 46.7127 36.4933C40.6698 41.2183 34.627 45.9433 28.5859 50.67C28.5234 50.6385 28.4609 50.6071 28.3984 50.5756C29.2482 45.8453 30.0997 41.1133 30.9727 36.2537H23.0661C23.0446 36.1872 23.0214 36.1208 23 36.0543C28.9268 31.4185 34.8537 26.7862 40.9733 22C40.0914 26.8894 39.2577 31.5234 38.3937 36.3184Z" fill="white"/>
    </svg>
  </div>
);

const UpgradeIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.20779" y="0.647727" width="27.2045" height="27.8379" rx="7.44064" stroke="black" stroke-width="1.29545"/>
    <path d="M16.3018 14.5H20.1987C20.2105 14.5273 20.2215 14.5554 20.2333 14.5827C17.3775 16.8157 14.5217 19.0487 11.6667 21.2825C11.6372 21.2676 11.6076 21.2527 11.5781 21.2379C11.9797 19.0024 12.3821 16.7661 12.7947 14.4695H9.05807C9.04795 14.4381 9.03698 14.4066 9.02686 14.3752C11.8278 12.1844 14.6288 9.9952 17.5209 7.73328C17.1041 10.044 16.7101 12.234 16.3018 14.5Z" fill="black"/>
  </svg>
);

const NavBar = ({ openSignIn }) => {
  const [user] = useAuthState(auth);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isPollsDropdownOpen, setIsPollsDropdownOpen] = useState(false);
  const profileDropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { surveyId } = useParams();
  const [currentPoll, setCurrentPoll] = useState(null);
  const [currentPollName, setCurrentPollName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);

  const debouncedSave = useCallback(
    debounce(async (newName, currentSurveyId, currentPoll) => {
      try {
        await saveSurvey(
          currentSurveyId,
          newName,
          currentPoll?.questions || [],
          currentPoll?.styles || {}
        );

        // After successful save, fetch the updated survey
        const updatedSurvey = await getSurvey(currentSurveyId);
        setCurrentPoll(updatedSurvey);
        setCurrentPollName(updatedSurvey.title);

        console.log('Survey title saved successfully:', newName);
      } catch (error) {
        console.error('Error saving survey title:', error);
        setCurrentPollName(currentPoll?.title || '');
      }
    }, 1000),
    []
  );

  // Update the useEffect to handle both /survey/ and /s/ routes
  useEffect(() => {
    const fetchSurveyDetails = async () => {
      try {
        // Extract surveyId from either /survey/:id or /s/:id routes
        const pathParts = location.pathname.split('/');
        const currentSurveyId = pathParts[pathParts.indexOf('survey') + 1] || surveyId;
        
        if (currentSurveyId) {
          const survey = await getSurvey(currentSurveyId);
          console.log('Fetched survey details:', survey); // Debug log
          setCurrentPoll(survey);
          setCurrentPollName(survey.title || '');
        } else {
          setCurrentPollName('');
        }
      } catch (error) {
        console.error('Error fetching survey details:', error);
        setCurrentPollName('');
      }
    };

    // Only fetch if we're on a survey route
    if (location.pathname.includes('/survey/')) {
      fetchSurveyDetails();
    } else {
      setCurrentPollName('');
    }
  }, [location.pathname, surveyId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const isSurveyViewerRoute = location.pathname.startsWith('/s/');
  console.log('Current path:', location.pathname);
  console.log('Is survey route?', isSurveyViewerRoute);

  if (isSurveyViewerRoute) {
    return null;
  }

  const handlePollNameChange = async (newName) => {
    try {
      // Replace with your actual update logic
      // await updatePollName(surveyId, newName);
      setCurrentPoll(prev => ({ ...prev, title: newName }));
    } catch (error) {
      console.error('Error updating poll name:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsProfileDropdownOpen(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleUpgradeClick = () => {
    console.log('NavBar Upgrade clicked');
    navigate('/pricing');
  };

  const handleNameEdit = () => {
    setIsEditingName(true);
  };

  const handleNameSave = async (newName) => {
    try {
      // Extract surveyId from the URL
      const pathParts = location.pathname.split('/');
      const currentSurveyId = pathParts[pathParts.indexOf('survey') + 1];
      
      if (!currentSurveyId) {
        throw new Error('Survey ID not found');
      }

      // Update local state immediately
      setCurrentPollName(newName);
      
      // Trigger debounced save
      debouncedSave(newName, currentSurveyId, currentPoll);

    } catch (error) {
      console.error('Error saving survey title:', error);
      setCurrentPollName(currentPoll?.title || '');
    }
  };

  return (
    <div className="fixed top-0 left-0 right-0 bg-white z-50">
      <nav className="flex items-center justify-between bg-white border-b h-14 w-full">
        <div className="flex items-center h-full">
          <Link to="/">
            <Logo />
          </Link>
          
          <div className="relative ml-4">
            <div className="flex items-center space-x-2 text-sm font-medium">
              <Link 
                to="/surveys" 
                className="cursor-pointer hover:border-b-2 hover:border-gray-300 pb-[1px]"
              >
                All Poll's
              </Link>
              {currentPollName && (
                <>
                  <ChevronRight size={16} className="text-gray-400 mx-2" />
                  {isEditingName ? (
                    <input
                      type="text"
                      defaultValue={currentPollName}
                      className="border-b border-gray-300 focus:border-blue-500 outline-none px-1 min-w-[300px]"
                      onChange={(e) => handleNameSave(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.target.blur();
                          setIsEditingName(false);
                        }
                      }}
                      autoFocus
                      onBlur={() => setIsEditingName(false)}
                    />
                  ) : (
                    <span 
                      onClick={handleNameEdit} 
                      className="cursor-pointer hover:border-b-2 hover:border-gray-300 pb-[1px]"
                    >
                      {currentPollName}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center h-full">
          {user && !user.isAnonymous ? (
            <>
              <button 
                onClick={handleUpgradeClick}
                className="hidden sm:flex items-center gap-2 px-8 h-full bg-[#3DEDBE] text-black hover:bg-opacity-90 transition-all duration-300 font-['HK_Nova'] font-bold"
              >
                <UpgradeIcon />
                Upgrade
              </button>
              
              <div 
                className="flex items-center space-x-3 ml-6 mr-4 relative"
                ref={profileDropdownRef}
              >
                <button
                  onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)}
                  className="flex items-center space-x-3 focus:outline-none"
                >
                  <span className="text-sm font-medium hover:text-gray-600 transition-colors">
                    {user.displayName || user.email}
                  </span>
                  <img
                    src={user.photoURL || '/default-avatar.png'}
                    alt="User avatar"
                    className="w-8 h-8 rounded-full cursor-pointer ring-2 ring-transparent hover:ring-gray-200 transition-all"
                  />
                </button>
                
                {isProfileDropdownOpen && (
                  <div className="absolute right-0 top-full mt-2 w-64 bg-white rounded-lg shadow-lg py-2 z-50 border border-gray-100 transform opacity-100 scale-100 transition-all duration-200 ease-out">
                    <div className="px-4 py-3 border-b border-gray-100">
                      <p className="text-sm font-semibold text-gray-900">{user?.displayName}</p>
                      <p className="text-sm text-gray-500 truncate">{user?.email}</p>
                    </div>
                    
                    <div className="py-2">
                      <Link
                        to="/profile"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors"
                        onClick={() => setIsProfileDropdownOpen(false)}
                      >
                        <svg className="w-4 h-4 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        Profile Settings
                      </Link>
                      
                      <button
                        onClick={handleLogout}
                        className="flex w-full items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors"
                      >
                        <svg className="w-4 h-4 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Sign out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <button
              onClick={openSignIn}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors mr-4"
            >
              Sign In
            </button>
          )}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
