// ui/src/SurveyDesigner.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QuestionDesigner from './QuestionDesigner';
import QuestionRenderer from './QuestionRenderer';
import { Zap, Check, Share2, Copy, Link2, X, Smartphone, Monitor, Pencil, ChevronLeft, ChevronRight, Plus, Play, ListChecks, TextCursor, AlignLeft, Hash, Star } from 'lucide-react';
import { saveSurvey, getSurvey } from './serverComm';
import debounce from 'lodash/debounce';
import { ChromePicker } from 'react-color';
import SurveyViewer from './SurveyViewer';

const SurveyDesigner = () => {
  const defaultStyles = {
    fontFamily: 'Inter',
    submitButtonColor: '#4F46E5',
    backgroundColor: '#FFFFFF',
    customSubmitText: '',
    useCustomSubmitText: false
  };

  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [saveStatus, setSaveStatus] = useState('idle'); // 'idle', 'saving', 'saved'
  const [surveyTitle, setSurveyTitle] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState('idle'); // 'idle' | 'copied'
  const [previewMode, setPreviewMode] = useState('desktop'); // 'desktop' | 'mobile'
  const [titleInputRef, setTitleInputRef] = useState(null);
  const [activeTab, setActiveTab] = useState('questions'); // 'questions' | 'style' | 'advanced'
  const [surveyStyles, setSurveyStyles] = useState(defaultStyles);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const [currentPreviewQuestion, setCurrentPreviewQuestion] = useState(0);
  const [selectedQuestionForEdit, setSelectedQuestionForEdit] = useState(null);
  const [activeEditorTab, setActiveEditorTab] = useState('edit'); // 'edit' | 'style' | 'advanced'
  const [showLogicConfig, setShowLogicConfig] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showMenu, setShowMenu] = useState(null);

  const questionTypeIcons = {
    'multiple choice': ListChecks,
    'short text': TextCursor,
    'long text': AlignLeft,
    'numeric': Hash,
    'star rating': Star
  };

  useEffect(() => {
    async function loadExistingSurvey() {
      if (!surveyId) return;

      try {
        const surveyData = await getSurvey(surveyId);
        setSurveyTitle(surveyData.title);
        setQuestions(surveyData.questions);
        setSurveyStyles(surveyData.styles || defaultStyles);
        
        // Automatically select the first question if available
        if (surveyData.questions && surveyData.questions.length > 0) {
          setSelectedQuestionForEdit(surveyData.questions[0]);
          setCurrentPreviewQuestion(0);
          setActiveEditorTab('edit');
        }
      } catch (error) {
        console.error('Error loading survey:', error);
      }
    }

    loadExistingSurvey();
  }, [surveyId]);

  const addQuestion = (question) => {
    const newQuestion = {
      id: uuidv4(),
      text: question.text || '',
      type: question.type || 'multiple choice',
      options: Array.isArray(question.options) ? question.options : [],
      ...question
    };

    setQuestions(prevQuestions => {
      const currentQuestions = Array.isArray(prevQuestions) ? prevQuestions : [];
      return [...currentQuestions, newQuestion];
    });
  };

  const deleteQuestion = (id) => {
    setQuestions(questions.filter(q => q.id !== id));
  };

  const onDragStart = () => {
    console.log('Drag started');
  };  

  const onDragEnd = (result) => {
    console.log('Drag ended', result);
    if (!result.destination) {
      console.log('No destination');
      return;
    }

    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    console.log('Reordering questions:', items);
    setQuestions(items);
  };

  const handleSaveSurvey = async (surveyData = null) => {
    try {
      setSaveStatus('saving');
      const dataToSave = {
        title: (surveyData?.title || surveyTitle || '').trim(),
        questions: Array.isArray(surveyData?.questions) ? surveyData.questions : 
                   Array.isArray(questions) ? questions : [],
        styles: {
          fontFamily: surveyData?.styles?.fontFamily || surveyStyles.fontFamily,
          submitButtonColor: surveyData?.styles?.submitButtonColor || surveyStyles.submitButtonColor,
          backgroundColor: surveyData?.styles?.backgroundColor || surveyStyles.backgroundColor,
          useCustomSubmitText: surveyData?.styles?.useCustomSubmitText || surveyStyles.useCustomSubmitText,
          customSubmitText: surveyData?.styles?.customSubmitText || surveyStyles.customSubmitText
        }
      };

      console.log('Saving survey with styles:', dataToSave.styles);

      await saveSurvey(
        surveyId,
        dataToSave.title,
        dataToSave.questions,
        dataToSave.styles
      );
      setSaveStatus('saved');
      setTimeout(() => setSaveStatus('idle'), 2000);
    } catch (error) {
      console.error('Error saving survey:', error);
      setSaveStatus('idle');
      alert('Failed to save survey. Please try again.');
    }
  };

  const handleViewAnalytics = () => {
    navigate(`/survey/${surveyId}/analytics`);
  };

  const getSaveButtonContent = () => {
    switch (saveStatus) {
      case 'saving':
        return (
          <>
            Saving... <Zap size={20} className="ml-2 animate-spin text-black fill-black" strokeWidth={2} />
          </>
        );
      case 'saved':
        return (
          <>
            Saved! <Check size={20} className="ml-2" strokeWidth={2} />
          </>
        );
      default:
        return (
          <>
            Publish <Zap size={20} className="ml-2 text-black fill-black" strokeWidth={2} />
          </>
        );
    }
  };

  const handleQuestionUpdate = (updatedQuestion) => {
    const updatedQuestions = questions.map(q => 
      q.id === updatedQuestion.id ? updatedQuestion : q
    );
    setQuestions(updatedQuestions);
    
    // Trigger save
    handleSaveSurvey({
      title: surveyTitle,
      questions: updatedQuestions,
      styles: surveyStyles
    });
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handleCopyLink = async () => {
    const surveyUrl = `${window.location.origin}/s/${surveyId}`;
    try {
      await navigator.clipboard.writeText(surveyUrl);
      setCopyStatus('copied');
      setTimeout(() => setCopyStatus('idle'), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const debouncedSave = useCallback(
    debounce(async (newTitle, questions, styles) => {
      setSaveStatus('saving');
      try {
        console.log('Saving styles (debounced):', styles);
        
        await saveSurvey(surveyId, newTitle, questions, {
          fontFamily: styles.fontFamily,
          submitButtonColor: styles.submitButtonColor,
          backgroundColor: styles.backgroundColor,
          useCustomSubmitText: styles.useCustomSubmitText,
          customSubmitText: styles.customSubmitText
        });
        setSaveStatus('saved');
        setTimeout(() => setSaveStatus('idle'), 2000);
      } catch (error) {
        console.error('Error saving survey:', error);
        setSaveStatus('idle');
        alert('Failed to save survey. Please try again.');
      }
    }, 1000),
    [surveyId]
  );

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setSurveyTitle(newTitle);
    debouncedSave(newTitle, questions, surveyStyles);
  };

  const handlePencilClick = () => {
    if (titleInputRef) {
      titleInputRef.focus();
      titleInputRef.select();
    }
  };

  const fonts = [
    { name: 'Inter', value: 'Inter' },
    { name: 'Roboto', value: 'Roboto' },
    { name: 'Open Sans', value: 'Open Sans' },
    { name: 'Lato', value: 'Lato' },
    { name: 'Poppins', value: 'Poppins' },
    { name: 'Montserrat', value: 'Montserrat' },
  ];

  const handleStyleChange = async (key, value) => {
    console.log(`Updating style ${key} to ${value}`);
    
    // Update local state
    const newStyles = {
      ...surveyStyles,
      [key]: value
    };
    setSurveyStyles(newStyles);

    try {
      // Save to server
      const saveData = {
        surveyId,
        title: surveyTitle,
        questions,
        styles: newStyles
      };
      console.log('Saving survey data:', saveData);

      const result = await saveSurvey(
        surveyId,
        surveyTitle,
        questions,
        newStyles
      );
      console.log('Save result:', result);

      // Verify the save by fetching the latest data
      const updatedSurvey = await getSurvey(surveyId);
      console.log('Updated survey:', updatedSurvey);

      // Update local state with server response
      if (updatedSurvey.styles) {
        setSurveyStyles(updatedSurvey.styles);
      }
    } catch (error) {
      console.error('Error saving style change:', error);
      // Optionally revert the local state if save fails
      setSurveyStyles(surveyStyles);
    }
  };

  const handlePreviewPoll = () => {
    const previewUrl = `${window.location.origin}/s/${surveyId}`;
    window.open(previewUrl, '_blank');
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.setData('text/plain', index);
    e.target.classList.add('dragging');
  };

  const handleDragEnd = (e) => {
    setDraggedIndex(null);
    setDragOverIndex(null);
    e.target.classList.remove('dragging');
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  const handleDrop = async (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = Number(e.dataTransfer.getData('text/plain'));
    
    if (dragIndex === dropIndex) return;

    const newQuestions = [...questions];
    const [draggedItem] = newQuestions.splice(dragIndex, 1);
    newQuestions.splice(dropIndex, 0, draggedItem);
    
    setQuestions(newQuestions);
    setDraggedIndex(null);
    setDragOverIndex(null);

    // Save the reordered questions to the database
    try {
      await handleSaveSurvey({
        title: surveyTitle,
        questions: newQuestions,
        styles: surveyStyles
      });
    } catch (error) {
      console.error('Error saving reordered questions:', error);
      // Optionally revert the order if save fails
      setQuestions(questions);
    }
  };

  // Handler for selecting a question to edit
  const handleQuestionSelect = (question, index) => {
    setSelectedQuestionForEdit(question);
    setCurrentPreviewQuestion(index); // Sync preview with selection
  };

  // Handler for preview navigation
  const handlePreviewNavigation = (index) => {
    setCurrentPreviewQuestion(index);
    setSelectedQuestionForEdit(questions[index]); // Sync editor with preview
  };

  const handleAddQuestion = (type) => {
    const newQuestion = {
      id: uuidv4(),
      text: 'New Question',
      type: type,
      options: type === 'multiple choice' ? ['Option 1', 'Option 2'] : []
    };
    
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions, newQuestion];
      // Set preview to show the new question (last index)
      setCurrentPreviewQuestion(updatedQuestions.length - 1);
      return updatedQuestions;
    });
    
    setShowQuestionModal(false);
  };

  const handleDuplicateQuestion = (questionToDuplicate) => {
    const duplicatedQuestion = {
      ...questionToDuplicate,
      id: uuidv4() // Generate new ID for duplicate
    };
    
    setQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];
      // Find index of original question and insert duplicate after it
      const index = newQuestions.findIndex(q => q.id === questionToDuplicate.id);
      newQuestions.splice(index + 1, 0, duplicatedQuestion);
      return newQuestions;
    });
  };

  // Add some CSS classes
  const styles = {
    dragging: 'opacity-50 border-2 border-dashed border-blue-500',
    dragOver: 'border-t-2 border-blue-500'
  };

  return (
    <div className="fixed inset-0 overflow-hidden">
      <div className="h-full pt-16">
        {/* Top Navigation Bar */}
        <div className="border-b border-gray-100 bg-white/95 backdrop-blur-sm">
          <div className="px-6">
            <div className="flex justify-between items-center h-[76px]">
              <div className="flex-1 min-w-0 flex items-center">
                <Pencil 
                  size={16} 
                  className="text-gray-400 mr-2 cursor-pointer hover:text-gray-600" 
                  onClick={handlePencilClick}
                />
                <input
                  ref={ref => setTitleInputRef(ref)}
                  type="text"
                  value={surveyTitle || ''}
                  onChange={handleTitleChange}
                  className="w-full text-2xl font-bold bg-transparent border-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 text-gray-900 placeholder-gray-400 rounded-lg hover:bg-gray-50 transition-colors p-1"
                  placeholder="Enter survey title..."
                />
              </div>
              
              {/* Action Buttons */}
              <div className="flex items-center gap-3 ml-4">
                <button
                  onClick={handlePreviewPoll}
                  className="py-2 px-4 rounded-lg transition-all duration-200 flex items-center bg-white text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 whitespace-nowrap"
                >
                  <Monitor size={20} className="mr-2" />
                  Preview Poll
                </button>
                <button
                  onClick={handleShare}
                  className="py-2 px-4 rounded-lg transition-all duration-200 flex items-center bg-white text-gray-700 border border-gray-200 hover:bg-gray-50 hover:border-gray-300 whitespace-nowrap"
                >
                  <Share2 size={20} className="mr-2" />
                  Share
                </button>

                <button
                  onClick={handleSaveSurvey}
                  disabled={saveStatus !== 'idle'}
                  className={`py-2 px-4 rounded-lg transition-all duration-200 flex items-center whitespace-nowrap font-medium
                    ${saveStatus === 'saved' 
                      ? 'bg-green-50 text-green-700 border border-green-200' 
                      : saveStatus === 'saving'
                      ? 'bg-[#FFFD63] text-black border border-[#FFFD63]'
                      : 'bg-[#FFFD63] text-black border border-[#FFFD63] hover:bg-[#e6e459] hover:border-[#e6e459] hover:shadow-md'
                    }`}
                >
                  {getSaveButtonContent()}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content - New 3-column layout */}
        <div className="flex h-[calc(100%-76px)]">
          {/* Left Panel - Questions List (13.3%) */}
          <div className="w-[13.3%] min-w-[200px] overflow-y-auto border-r border-gray-200 bg-white">
            <div className="space-y-1 p-2">
              {questions.map((question, index) => (
                <div
                  key={question.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragEnd={handleDragEnd}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, index)}
                  onClick={() => handleQuestionSelect(question, index)}
                  className={`relative group flex items-start p-2 cursor-move rounded-lg ${
                    selectedQuestionForEdit?.id === question.id
                      ? 'bg-gray-50'
                      : 'hover:bg-gray-50'
                  } ${dragOverIndex === index ? 'border-t-2 border-blue-500' : ''}`}
                >
                  {/* Question Number and Type Icon Box */}
                  <div className="flex-shrink-0 mr-2 mt-1">
                    <div className="w-6 h-6 rounded-lg bg-gray-100 flex items-center justify-center relative">
                      <span className="text-[11px] font-medium text-gray-500">{index + 1}</span>
                      {questionTypeIcons[question.type] && (
                        <div className="absolute -bottom-1 -right-1 w-3.5 h-3.5 rounded-full bg-blue-100 flex items-center justify-center">
                          {React.createElement(questionTypeIcons[question.type], {
                            size: 10,
                            className: "text-blue-600"
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Question Content */}
                  <div className="flex-1 min-w-0">
                    <div className="font-medium text-[13px] text-gray-900 line-clamp-2 leading-4">
                      {question.text || 'Untitled Question'}
                    </div>
                    <div className="text-[11px] text-gray-500 mt-0.5">
                      {question.type}
                    </div>
                  </div>
                  
                  {/* Three dots menu - visible on hover */}
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity relative mt-1">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowMenu(question.id);
                      }}
                      className="p-0.5 hover:bg-gray-100 rounded-full"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                      </svg>
                    </button>
                    
                    {showMenu === question.id && (
                      <div 
                        className="absolute right-0 mt-1 py-1 w-32 bg-white rounded-lg shadow-xl border border-gray-200 z-50"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          onClick={() => {
                            handleDuplicateQuestion(question);
                            setShowMenu(null);
                          }}
                          className="w-full text-left px-3 py-1 text-xs text-gray-700 hover:bg-gray-50"
                        >
                          Duplicate
                        </button>
                        <button
                          onClick={() => {
                            deleteQuestion(question.id);
                            setShowMenu(null);
                          }}
                          className="w-full text-left px-3 py-1 text-xs text-red-600 hover:bg-gray-50"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Middle Panel - Preview (73.4%) */}
          <div className="w-[73.4%] bg-gray-50 overflow-y-auto">
            <div className="h-full">
              {/* Preview Controls */}
              <div className="sticky top-0 bg-gray-50 p-4 border-b border-gray-200 z-10">
                <div className="flex items-center justify-between px-4 py-2">
                  {/* Left side - Add Question */}
                  <button
                    onClick={() => setShowQuestionModal(true)}
                    className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    <Plus size={16} />
                    Add Question
                  </button>

                  {/* Right side - Preview controls */}
                  <div className="flex items-center gap-2">
                    <div className="flex items-center bg-gray-100 rounded-md p-1">
                      <button
                        onClick={() => setPreviewMode('desktop')}
                        className={`p-1.5 rounded ${
                          previewMode === 'desktop'
                            ? 'bg-white shadow-sm'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        <Monitor size={16} />
                      </button>
                      <button
                        onClick={() => setPreviewMode('mobile')}
                        className={`p-1.5 rounded ${
                          previewMode === 'mobile'
                            ? 'bg-white shadow-sm'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        <Smartphone size={16} />
                      </button>
                    </div>
                    
                    <button
                      onClick={() => setShowPreviewModal(true)}
                      className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
                    >
                      <Play size={16} />
                      Preview
                    </button>
                  </div>
                </div>
              </div>

              {/* Preview Container */}
              <div className="p-6 flex justify-center">
                <div 
                  className={`rounded-xl shadow-sm overflow-visible mx-auto ${
                    previewMode === 'mobile' ? 'w-[375px]' : 'w-[1024px]'
                  }`}
                  style={{ 
                    fontFamily: surveyStyles.fontFamily,
                    backgroundColor: surveyStyles.backgroundColor 
                  }}
                >
                  <div className="p-4 border-b border-gray-200">
                    <h2 className="text-lg font-semibold">{surveyTitle || 'Untitled Survey'}</h2>
                  </div>

                  {/* Progress Bar */}
                  <div className="px-4 pt-4">
                    <div className="text-sm text-gray-500 mb-2 text-center">
                      Question {currentPreviewQuestion + 1} of {questions.length}
                    </div>
                    <div className="w-full bg-gray-200 h-2 rounded-full">
                      <div 
                        className="bg-blue-500 h-2 rounded-full transition-all duration-300"
                        style={{ 
                          width: `${((currentPreviewQuestion + 1) / questions.length) * 100}%`
                        }}
                      />
                    </div>
                  </div>

                  {/* Single Question Preview */}
                  <div className="p-4">
                    {questions[currentPreviewQuestion] && (
                      <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
                        {/* Question Text Input */}
                        <input
                          type="text"
                          value={questions[currentPreviewQuestion].text}
                          onChange={(e) => {
                            const newQuestions = [...questions];
                            newQuestions[currentPreviewQuestion] = {
                              ...newQuestions[currentPreviewQuestion],
                              text: e.target.value
                            };
                            setQuestions(newQuestions);
                            // Auto-save after changes
                            debouncedSave(surveyTitle, newQuestions, surveyStyles);
                          }}
                          className="w-full text-lg font-medium mb-4 p-2 border border-transparent hover:border-gray-200 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                          placeholder="Enter question text..."
                        />

                        {/* Options (for multiple choice questions) */}
                        {questions[currentPreviewQuestion].type === 'multiple choice' && (
                          <div className="space-y-2">
                            {questions[currentPreviewQuestion].options.map((option, optionIndex) => (
                              <div key={optionIndex} className="flex items-center gap-2">
                                <div className="w-4 h-4 rounded-full border border-gray-300 flex-shrink-0" />
                                <input
                                  type="text"
                                  value={option}
                                  onChange={(e) => {
                                    const newQuestions = [...questions];
                                    newQuestions[currentPreviewQuestion].options[optionIndex] = e.target.value;
                                    setQuestions(newQuestions);
                                    // Auto-save after changes
                                    debouncedSave(surveyTitle, newQuestions, surveyStyles);
                                  }}
                                  className="flex-1 p-2 text-sm border border-transparent hover:border-gray-200 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                                  placeholder={`Option ${optionIndex + 1}`}
                                />
                              </div>
                            ))}
                            
                            {/* Add Option Button */}
                            <button
                              onClick={() => {
                                const newQuestions = [...questions];
                                newQuestions[currentPreviewQuestion].options.push(`Option ${questions[currentPreviewQuestion].options.length + 1}`);
                                setQuestions(newQuestions);
                                // Auto-save after adding option
                                debouncedSave(surveyTitle, newQuestions, surveyStyles);
                              }}
                              className="mt-2 text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
                            >
                              <Plus size={16} />
                              Add Option
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Navigation Buttons */}
                  <div className="p-4 flex justify-between">
                    <button
                      onClick={() => handlePreviewNavigation(Math.max(0, currentPreviewQuestion - 1))}
                      disabled={currentPreviewQuestion === 0}
                      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
                        ${currentPreviewQuestion === 0 
                          ? 'text-gray-400 cursor-not-allowed' 
                          : 'text-gray-700 hover:bg-gray-100'}`}
                    >
                      <ChevronLeft size={20} />
                      Previous
                    </button>

                    <button
                      onClick={() => handlePreviewNavigation(Math.min(questions.length - 1, currentPreviewQuestion + 1))}
                      disabled={currentPreviewQuestion === questions.length - 1}
                      className="flex items-center gap-2 px-6 py-2 rounded-lg text-white"
                      style={{ backgroundColor: surveyStyles.submitButtonColor }}
                    >
                      Next
                      <ChevronRight size={20} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Panel - Question Editor (13.3%) */}
          <div className="w-[13.3%] min-w-[200px] overflow-y-auto border-l border-gray-200 bg-white">
            {/* Tabs - Always visible */}
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px">
                {['Edit', 'Style', 'Advanced'].map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setActiveEditorTab(tab.toLowerCase())}
                    className={`px-3 py-2 text-xs font-medium transition-colors
                      ${activeEditorTab === tab.toLowerCase()
                        ? 'border-b-2 border-blue-500 text-blue-600'
                        : 'border-b-2 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }
                    `}
                  >
                    {tab}
                  </button>
                ))}
              </nav>
            </div>

            {/* Tab Content */}
            <div className="p-3">
              {activeEditorTab === 'edit' && selectedQuestionForEdit && (
                <QuestionDesigner 
                  existingQuestion={selectedQuestionForEdit} 
                  onQuestionUpdate={handleQuestionUpdate} 
                />
              )}

              {activeEditorTab === 'style' && (
                <div className="space-y-3">
                  {/* Global Style Settings - Always visible under Style tab */}
                  <div>
                    <label className="block text-xs font-medium text-gray-600 mb-1">
                      Font Family
                    </label>
                    <select
                      value={surveyStyles.fontFamily}
                      onChange={(e) => handleStyleChange('fontFamily', e.target.value)}
                      className="w-full px-2 py-1.5 text-xs border border-gray-200 rounded-md focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    >
                      {fonts.map(font => (
                        <option key={font.value} value={font.value}>
                          {font.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Background Color */}
                  <div>
                    <label className="block text-xs font-medium text-gray-600 mb-1">
                      Background Color
                    </label>
                    <div className="relative">
                      <button
                        onClick={() => setShowBgColorPicker(!showBgColorPicker)}
                        className="w-full px-2 py-1.5 text-xs border border-gray-200 rounded-md text-left flex items-center gap-2"
                      >
                        <div
                          className="w-4 h-4 rounded border border-gray-200"
                          style={{ backgroundColor: surveyStyles.backgroundColor }}
                        />
                        {surveyStyles.backgroundColor}
                      </button>
                      {showBgColorPicker && (
                        <div className="absolute top-full right-0 mt-2 z-10">
                          <ChromePicker
                            color={surveyStyles.backgroundColor}
                            onChange={(color) => handleStyleChange('backgroundColor', color.hex)}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Button Color */}
                  <div>
                    <label className="block text-xs font-medium text-gray-600 mb-1">
                      Button Color
                    </label>
                    <div className="relative">
                      <button
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        className="w-full px-2 py-1.5 text-xs border border-gray-200 rounded-md text-left flex items-center gap-2"
                      >
                        <div
                          className="w-4 h-4 rounded border border-gray-200"
                          style={{ backgroundColor: surveyStyles.submitButtonColor }}
                        />
                        {surveyStyles.submitButtonColor}
                      </button>
                      {showColorPicker && (
                        <div className="absolute top-full right-0 mt-2 z-10">
                          <ChromePicker
                            color={surveyStyles.submitButtonColor}
                            onChange={(color) => handleStyleChange('submitButtonColor', color.hex)}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Question-specific style options */}
                  {selectedQuestionForEdit && (
                    <div className="mt-4 pt-4 border-t border-gray-200">
                      {/* Your existing question-specific style options */}
                    </div>
                  )}
                </div>
              )}

              {activeEditorTab === 'advanced' && selectedQuestionForEdit && (
                <div>
                  {/* Your existing advanced tab content */}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Share Modal */}
        {showShareModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 m-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900">Share Survey</h3>
                <button
                  onClick={() => setShowShareModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X size={20} />
                </button>
              </div>

              <div className="space-y-4">
                {/* Survey Link */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Survey Link
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      readOnly
                      value={`${window.location.origin}/s/${surveyId}`}
                      className="flex-1 p-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <button
                      onClick={handleCopyLink}
                      className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      {copyStatus === 'copied' ? (
                        <>
                          <Check size={16} className="text-green-500" />
                          Copied!
                        </>
                      ) : (
                        <>
                          <Copy size={16} />
                          Copy
                        </>
                      )}
                    </button>
                  </div>
                </div>

                {/* Share Options */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Share via
                  </label>
                  <div className="grid grid-cols-2 gap-3">
                    {[
                      { name: 'Email', icon: 'mail', color: 'bg-blue-50 text-blue-600' },
                      { name: 'WhatsApp', icon: 'message-circle', color: 'bg-green-50 text-green-600' },
                    ].map((option) => (
                      <button
                        key={option.name}
                        className={`flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors ${option.color}`}
                      >
                        <span className="text-sm font-medium">{option.name}</span>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Privacy Settings */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Privacy Settings
                  </label>
                  <select
                    className="w-full p-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  >
                    <option value="public">Public - Anyone with the link</option>
                    <option value="private">Private - Only specific people</option>
                  </select>
                </div>
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setShowShareModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {showLogicConfig && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 m-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900">Question Logic</h3>
                <button
                  onClick={() => setShowLogicConfig(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X size={20} />
                </button>
              </div>

              <div className="space-y-4">
                {/* Logic configuration content */}
                <p className="text-sm text-gray-600">
                  Configure conditional logic for this question.
                </p>
                {/* Add your logic configuration UI here */}
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setShowLogicConfig(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {showQuestionModal && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-2xl shadow-xl max-w-md w-full m-4 overflow-hidden">
              {/* Header */}
              <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">
                  Choose Question Type
                </h3>
                <button
                  onClick={() => setShowQuestionModal(false)}
                  className="text-gray-400 hover:text-gray-500 p-1 hover:bg-gray-50 rounded-full transition-colors"
                >
                  <X size={20} />
                </button>
              </div>

              {/* Question Types Grid */}
              <div className="p-6">
                <div className="grid grid-cols-1 gap-3">
                  {[
                    { 
                      type: 'multiple choice', 
                      label: 'Multiple Choice',
                      icon: ListChecks,
                      description: 'Let respondents choose from predefined options'
                    },
                    { 
                      type: 'short text', 
                      label: 'Short Text',
                      icon: TextCursor,
                      description: 'Collect brief text responses'
                    },
                    { 
                      type: 'long text', 
                      label: 'Long Text',
                      icon: AlignLeft,
                      description: 'Gather detailed text responses'
                    },
                    { 
                      type: 'numeric', 
                      label: 'Numeric',
                      icon: Hash,
                      description: 'Collect numerical data'
                    },
                    { 
                      type: 'star rating', 
                      label: 'Star Rating',
                      icon: Star,
                      description: 'Get feedback on a rating scale'
                    }
                  ].map(({ type, label, icon: Icon, description }) => (
                    <button
                      key={type}
                      onClick={() => handleAddQuestion(type)}
                      className="flex items-center gap-4 p-4 text-left border border-gray-200 rounded-xl hover:border-blue-500 hover:bg-blue-50 transition-all group"
                    >
                      <div className="flex-shrink-0">
                        <div className="w-10 h-10 rounded-lg bg-blue-100 text-blue-600 flex items-center justify-center group-hover:bg-blue-600 group-hover:text-white transition-colors">
                          <Icon size={20} />
                        </div>
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="font-medium text-gray-900 group-hover:text-blue-600 transition-colors">
                          {label}
                        </div>
                        <div className="text-sm text-gray-500">
                          {description}
                        </div>
                      </div>
                      <ChevronRight size={16} className="text-gray-400 group-hover:text-blue-500" />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {showPreviewModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className={`bg-white rounded-xl shadow-xl ${
              previewMode === 'mobile' ? 'max-w-sm' : 'max-w-2xl'
            } w-full m-4 h-[80vh] overflow-hidden`}>
              <div className="flex justify-between items-center p-4 border-b">
                <h3 className="text-lg font-semibold text-gray-900">
                  Survey Preview
                </h3>
                <button
                  onClick={() => setShowPreviewModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X size={20} />
                </button>
              </div>
              <div className="p-4 h-[calc(100%-65px)] overflow-y-auto">
                <div style={{ 
                  fontFamily: surveyStyles.fontFamily || 'Inter, sans-serif',
                  backgroundColor: surveyStyles.backgroundColor 
                }}>
                  <SurveyViewer 
                    survey={{ questions, styles: surveyStyles }}
                    previewMode={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyDesigner;
