import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://surv-server.vercel.app';

const firebaseConfig = {
  apiKey: "AIzaSyCIUDcL-SK_5bAm0t4KkfnWcln18n8yrfg",
  authDomain: "surveyappreact.firebaseapp.com",
  projectId: "surveyappreact",
  storageBucket: "surveyappreact.appspot.com",
  messagingSenderId: "366662516624",
  appId: "1:366662516624:web:15257e6d56c2c56ee83d15"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const getAuthToken = async () => {
  let user = await getCurrentUser();

  if (!user) {
    await signInAnonymously(auth);
    user = await getCurrentUser();
  }

  try {
    return await user.getIdToken();
  } catch (error) {
    console.error('Error getting auth token:', error);
    throw new Error('Failed to get authentication token');
  }
};

const getAuthHeader = async () => {
  const token = await getAuthToken();
  return { Authorization: `Bearer ${token}` };
};

const apiCall = async (method, endpoint, data = null) => {
  try {
    const headers = await getAuthHeader();
    headers['Content-Type'] = 'application/json';

    const url = `${API_BASE_URL}${endpoint}`;
    const options = {
      method,
      headers,
      body: data ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};

export const get = (endpoint) => apiCall('GET', endpoint);
export const post = (endpoint, data) => apiCall('POST', endpoint, data);
export const del = (endpoint) => apiCall('DELETE', endpoint);

export const createSurvey = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const token = await user.getIdToken();
    
    const response = await fetch(`${API_BASE_URL}/api/createSurvey`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: 'Untitled Survey',
        questions: [],
        styles: {
          fontFamily: 'Inter',
          submitButtonColor: '#4F46E5',
          backgroundColor: '#FFFFFF',
          useCustomSubmitText: false,
          customSubmitText: ''
        }
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating survey:', error);
    throw error;
  }
};

export const saveSurvey = async (surveyId, title, questions, styles) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const token = await user.getIdToken();
    console.log('Saving survey to:', `${API_BASE_URL}/api/saveSurvey`);

    const response = await fetch(`${API_BASE_URL}/api/saveSurvey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Origin': window.location.origin
      },
      mode: 'cors',
      body: JSON.stringify({
        surveyId,
        title,
        questions,
        styles: {
          fontFamily: styles.fontFamily || 'Inter',
          submitButtonColor: styles.submitButtonColor || '#4F46E5',
          backgroundColor: styles.backgroundColor || '#FFFFFF',
          useCustomSubmitText: styles.useCustomSubmitText || false,
          customSubmitText: styles.customSubmitText || ''
        }
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Save response:', data);
    return data;
  } catch (error) {
    console.error('Error in saveSurvey:', error);
    throw error;
  }
};
export const getSurveys = async () => {
  const token = await auth.currentUser?.getIdToken();
  
  try {
    const response = await fetch('https://surv-server.vercel.app/api/getSurveys', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching surveys:', error);
    throw error;
  }
};
export const getSurvey = async (surveyId) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const response = await fetch(`${API_BASE_URL}/api/getSurvey/${surveyId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${await user.getIdToken()}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching survey:', error);
    throw error;
  }
};
export const checkUserResponse = (surveyId) => get(`/api/checkResponse/${surveyId}`);
export const submitSurveyResponse = (surveyId, answers) => post('/api/submitResponse', { surveyId, answers });
export const getSurveyWithResponses = (surveyId) => get(`/api/surveys/${surveyId}/with-responses`);
export const deleteSurvey = async (surveyId) => {
  const token = await auth.currentUser?.getIdToken();
  
  try {
    const response = await fetch(`${API_BASE_URL}/api/surveys/${surveyId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error deleting survey:', error);
    throw error;
  }
};

// Aliases for consistency with existing code
export const fetchSurvey = getSurvey;
export const submitResponse = async (surveyId, answers) => {
  try {
    const response = await post('/api/submitResponse', { surveyId, answers });
    console.log('Submit response successful:', response);
    return response;
  } catch (error) {
    console.error('Error in submitResponse:', error);
    throw error;
  }
};

export const checkResponse = async (surveyId) => {
  try {
    return await checkUserResponse(surveyId);
  } catch (error) {
    console.error('Error checking response:', error);
    return { hasResponded: false };
  }
};

export const duplicateSurvey = async (surveyId) => {
  const token = await auth.currentUser?.getIdToken();
  
  try {
    const response = await fetch(`${API_BASE_URL}/api/duplicateSurvey/${surveyId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error duplicating survey:', error);
    throw error;
  }
};
